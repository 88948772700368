// YANDEX MAP

main();

async function main() {
    // Waiting for all api elements to be loaded
    await ymaps3.ready;
    const {
        YMap,
        YMapDefaultSchemeLayer,
        YMapDefaultFeaturesLayer,
    } = ymaps3;

    const {YMapDefaultMarker} = await ymaps3.import('@yandex/ymaps3-markers@0.0.1');

    // Initialize the map
    map = new YMap(
        // Pass the link to the HTMLElement of the container
        document.getElementById('yandex'),
        // Pass the map initialization parameters
        {
            location: {
                center: [30.316425, 59.968142], // starting position [lng, lat]
                zoom: 16
            },
            showScaleInCopyrights: false,
            behaviors: ["drag", "pinchZoom", "mouseTilt"]
        },
        // Add a map scheme layer
        [
            new YMapDefaultSchemeLayer({}),
            new YMapDefaultFeaturesLayer({})
        ]
    );

    map.addChild(new YMapDefaultMarker({
        coordinates: [30.316425, 59.968142],
        title: 'Ленполиграфмаш',
        subtitle: 'Машиностроительный завод',
        color: 'black',
    }))
}
